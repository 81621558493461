@charset "UTF-8";

/*
#overview
base
*/
/*
#colors
@$color1: #fff
@$color2: #016db8
@$color3: #959595
@$color4: #0173bc
@$color5: #6fb120
@$color6: #59666e
@$color7: #1695e6
@$textColor: #3d3d3d
@$animationColor: #d5d6d8
*/
$color1: #ffff;
$color2: #016db8;
$color3: #959595;
$color4: #0173bc;
$color5: #6fb120;
$color6: #59666e;
$color7: #1695e6;
$textColor: #3d3d3d;
$animationColor: #d5d6d8;


// font
$baseFont: 15;
$lineHeight: 1.8;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
$Gothic: "Noto Sans JP","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$Roboto: "Roboto","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

// コンテンツ幅
$baseWidth: 1120px;
$baseWidthSp: 91.25%;
$baseWidthPadding: 4.375%;

// キャンバスサイズ
$maxWidth: 2000px;

// ブレークポイント
$bpPcs: 1024px;
$bpTb: 768px;
$bpSp: 640px;
$bpSps: 425px;


// z-index
// ↑レイヤー上
// ↓レイヤー下
// eg. z-index: z(child);
$z-index:(
  child,
  nav,
  pulldown,
  header
);
// リストを逆転させる
@function list-reverse($map) {
  $result: ();
  @for $i from length($map)*-1 through -1 {
    $result: append($result, nth($map, abs($i)), comma);
  }
  @return $result;
}
$z-index: list-reverse($z-index);

@function z($name) {
  @return index($z-index, $name);
}
