@charset "UTF-8";

@import "../_setting";
@import "../_mixin";
// @import "../module/_button";
// @import "../module/_title";
// @import "../module/_other";

.p-top {
  &__mainvisual {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -82px;
      top: -12.5%;
      z-index: 1;
      background-image: url(../../img/top/before-mainvisual-img.png);
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 19vw;
      pointer-events: none;
      // animation:1s ease 2s normal forwards 1 fadein;
      // opacity: 0;
      @include bpSp {
        background-image: url(../../img/top/before-mainvisual-img__sp.png);
        height: 29vw;
        top: -4%;
      }
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -9.5%;
      background-image: url(../../img/top/after-mainvisual-img.png);
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 21vw;
      pointer-events: none;
      animation:0.3s ease 0.2s normal forwards 1 fadein;
      opacity: 0;
      @include bpSp {
        background-image: url(../../img/top/after-mainvisual-img__sp.png);
        height: 30vw;
        bottom: -5.5%;
      }
    }

    @keyframes fadein{from{opacity:0}
      to{opacity:1}
      }

      @-webkit-keyframes fadein{from{opacity:0}
      to{opacity:1}
      }

    .top-slide {
      &::before {
        content: "";
        display: block;
        position: absolute;
        padding-top: 56.9%;
        width: 100%;
      }
      img {
        width: 100%;
      }
    }

    .top-slide_item {
      position: relative;
    }
    .m-btn01 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 155px;
      text-align: center;
      z-index: 2;
      @include bpPcs {
        bottom: 87px;
      }
      @include bpSp {
        bottom: 12%;
        text-align: left;
      }
      &-large {
        width: 355px;
        @include bpPcs {
          width: 250px;
        }
        @include bpSp {
          width: 220px;
        }
      }
    }
  }
  &__news {
    padding-top: 118px;
    @include bpSp {
      padding-bottom: 30px;
      padding-top: 75px;
    }
    .news-wrap {
      display: flex;
      @include bpTb {
        flex-direction: column-reverse;
      }
      &__list {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 369px);
        padding-right: 89px;
        flex-wrap: wrap;
        @include bpPcs {
          padding-right: 30px;
        }
        @include bpTb {
          width: 100%;
          padding-left: 30px;
          padding-right: 30px;
        }
        @include bpSp {
          padding-left: 0;
          padding-right: 0;
          justify-content: center;
        }
        .item {
          display: inline-block;
          position: relative;
          width: 46.073%;
          margin-bottom: 21px;
          .item-img {
            height: 183px;
            @include breakpoint(1140px) {
              height: 15.7vw;
            }
            @include bpPcs {
              height: 23.1vw;
            }
            @include bpSp {
              height: 26.8vw;
            }
            img {
              transition: all 0.2s;
            }
          }
          .image_path {
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transition: all 0.5s;
          }
          &:hover {
            .item-img {
              img {
                transform: scale(1.15);
              }
            }
            .image_path {
              height: 100%;
              transform: scale(1.07);
            }
          }
          @include bpSp {
            width: 48%;
            margin-bottom: 30px;
            &:first-of-type {
              display: block;
              width: 80%;
              margin: 0 auto 30px;

              .img-overhide {
                    height: 43.4vw;
              }
            }
            &:nth-child(2) {
              margin-right: 2%;
            }
            &:nth-child(4) {
               display: none;
            }
          }
          &:hover {
            text-decoration: none;
          }
          &.ico-new {
            &::before {
              content: 'new';
              position: absolute;
              @include f-size(14);
              color: $color1;
              width: 65px;
              text-transform: uppercase;
              font-family: $Roboto;
              background-color: #f96d52;
              text-align: center;
              z-index: 1;

              @include bpSp {
                @include f-size(13);
              }
            }
          }
          &-img {
            @include bpSp {
              img {
                width: 100%;
              }
            }
          }
          &-content {
            padding-top: 25px;

            @include bpSp {
              padding-top: 7px;
            }
            .date-category {
              display: flex;
              flex-wrap: wrap;
              .date {
                @include f-size(14);
                color: $color3;
                margin-right: 14px;
                @include bpSp {
                  margin-bottom: 4px;
                  width: 100%;
                  display: block;
                }
              }
              .category {
                @include f-size(13);
                border-radius: 13.425px;
                border: 1px solid;
                width: 77px;
                text-align: center;
                color: $color4;
                margin-right: 7px;
                margin-bottom: 6px;

                @include bpSp {
                  width: auto;
                  padding-left: 12px;
                  padding-right: 12px;
                }
                @include bpSp {
                  @include f-size(12);
                }
                
              }
            }
            .title {
              @include f-size(16);
              margin-top: 10px;
              line-height: 1.5;
              height: 48px;
              overflow: hidden;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              @include bpSp {
                height: 42px;
                @include f-size(14);
              }
            }
          }
        }
      }
      &__title {
        background-color: #d6f0ff;
        width: 369px;
        padding: 75px 54px 60px;
        margin-bottom: 54px;
        @include bpTb{
          width: 100%;
          background-color: $color1;
          padding: 0;
          margin-bottom: 0;
        }
        .m-title-img {
          text-align: right;
          @include bpTb {
            text-align: left;
            position: relative;
            background-color: transparent;
            &:before {
              content: '';
              position: absolute;
              left: -5%;
              top: -20px;
              width: 332px;
              height: 130%;
              background-color: #d6f0ff;
              @include bpSp {
                width: 220px;
              }
            }
            img, .m-title-img__ja {
              position: relative;
            }
          }
          &__ja {
            color: $color4;
          }
          @include bpSp {
            img {
              width: 180px;
            }
          }
        }
      }
      .category-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 30px;
        margin-bottom: 32px;
        @include bpPcs {
          justify-content: center;
          margin-top: 50px;
        }
        @include bpSp {
          margin-top: 30px;
        }
        .item {
          width: 105px;
          text-align: right;
          margin: 6px 0;
          span {
            padding: 5px;
            @include bpSp {
              padding: 2px;
            }
            &:hover {
              text-decoration: underline;
              opacity: 0.75;
              cursor: pointer;
            }
          }
          @include bpTb {
            text-align: center;
          }
          @include bpSp {
            width: 80px;
            margin: 0;
          }
          &:nth-child(1) {
            width: auto;
            @include bpSp {
              width: 80px;
            }
          }
          &:nth-child(4) {
            width: 110px;
            @include bpTb {
              width: 105px;
            }
            @include bpSp {
              width: 80px;
            }
          }
          a {
            @include f-size(15);
            @include bpSp {
              @include f-size(13);
            }
            &.is-current {
              text-decoration: underline;
            }
          }
        }
      }
      .btn-wrap {
        text-align: right;
        &__pc {
          @include bpTb {
            display: none;
          }
        }
        &__sp {
          display: none;
          @include bpTb {
            display: block;
            text-align: center;
            width: 100%;
          }
        }

        &.btn001 {
          display: none;
          @include bpTb {
            display: block;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }
  .obj-img01 {
    margin-top: -54px;
    @include bpTb {
      margin-top: 0;
    }
  }
  &__company {
    position: relative;
    padding-bottom: 34px;
    @include bpSp {
      padding-top: 30px;
    }
    &:before {
      content: "";
      position: absolute;
      max-width: 1379px;
      height: 331px;
      width: 100%;
      left: 50%;
      top: 45.5%;
      transform: translateX(-50%);
      background: rgb(214,240,255);
      background: linear-gradient(90deg, rgba(214,240,255,1) 0%, rgba(238,255,217,1) 100%);
      z-index: -1;
      @include bpSp {
        top: 37.5%;
        height: 45%;
      }
    }
    .m-title-img {
      @include bpSp {
        text-align: center;
      }
      &__ja {
        top: -27px;
        position: relative;
        margin-top: 0;
        @include bpSp {
          top: 0;
        }
      }
      @include bpSp {
        img {
          width: 205px;
        }
      }
    }
    .company-wrap01 {
      display: flex;
      margin-top: 20px;
      margin-bottom: 107px;
      @include bpTb {
        flex-direction: column;
      }
      @include bpSp {
        margin-bottom: 75px;
      }
      &__img {
        width: 55.804%;
        z-index: 1;
        @include bpTb {
          width: 100%;
          text-align: center;
        }
        @include bpSp {
          width: 90%;
          margin: 0 auto;
        }
      }
      &__content {
        width: 44.196%;
        padding-left: 90px;
        position: relative;
        margin-top: 59px;
        padding-top: 83px;
        @include bpPcs {
          padding-left: 28px;
          padding-top: 33px;
        }
        @include bpTb {
          width: 100%;
          margin-top: 0;
          padding-left: 0;
          text-align: center;
          padding-top: 20px;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          right: 0;
          top: 0;
          background-color: $color2;
          z-index: -1;
          width: 127.885%;
          height: 116.13%;
          max-width: 633px;
          max-height: 360px;
          @include bpTb {
            max-width: 100%;
            left: 0;
            height: 150%;
            top: -50px;
          }
        }
        .txt {
          @include f-size(16);
          color: $color1;
          margin-bottom: 30px;
          @include bpSp {
            @include f-size(14);
            margin-bottom: 15px;
          }
        }
      }
    }
    .company-wrap02 {
      display: flex;
      justify-content: space-between;
      padding: 0 60px;
      flex-wrap: wrap;
      @include bpPcs {
        padding: 0;
      }
      .item {
        width: 22.7%;
        @include bpTb {
          width: 47%;
          margin-bottom: 20px;
          img {
            width: 100%;
          }
        }
        .txt {
          @include f-size(16);
          font-weight: 500;
          padding-top: 10px;
          padding-bottom: 18px;
          @include bpSp {
            padding-bottom: 10px;
            @include f-size(14);
          }
        }
        .btn-wrap {
          text-align: right;
        }

        .m-linkblock {
          &:hover {
            .btn-wrap {
              .m-btn03 {
                &:after {
                  right: -4px;
                }
              }
            }
          }
        }
      }
    }
  }
  .obj-img02 {
  }
  &__brand {
    margin-top: -80px;
    position: relative;
    z-index: 1;
    @include bpSp {
      margin-top: 0;
      padding-bottom: 40px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 25%;
      width: 100%;
      height: 500px;
      background: #d6f0ff;
      background: linear-gradient(90deg, #d6f0ff 0%, #eeffd9 100%);
      @include bpSp {
        top: 19%;
        height: 61%;
        left: -5%;
        width: 110%;
      }
    }
    .m-title-img {
      text-align: right;
      @include bpSp {
        text-align: center;
        img {
          width: 190px;
        }
      }
    }
    .brand-list {
      margin-top: -50px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 60px;
      @include bpPcs {
        padding: 0;
      }
      @include bpSp {
        margin-top: 30px;
      }
      .item {
        padding-left: 22px;
        width: 45.2%;
        margin-bottom: 16px;
        @include bpTb {
          width: 47%;
        }
        @include bpSp {
          width: 100%;
          padding-left: 0;
          margin-left: 4.5%;
          margin-bottom: 40px;
        }
        &:nth-child(1) {
          .item-img {
            .logo-brand {
              @include bpSp {
                width: 57%;
              }
            }
          }
        }
        &:nth-child(2) {
          margin-top: 73px;
          margin-left: 9.5%;
          @include bpTb {
            margin-left: 4.5%;
          }
          @include bpSp {
            margin-top: auto;
          }
          .item-img {
            .logo-brand {
              @include bpSp {
                width: 63%;
              }
            }
          }
        }
        &:nth-child(3) {
          .item-img {
            .logo-brand {
              @include bpSp {
                box-shadow: 0px 0px 15px 4px rgba($color: #000000, $alpha: 0.1);
                width: 56%;
                left: -15px;
                top: 0;
              }
            }
          }
        }
        &-img {
          position: relative;
          padding-top: 24px;
          .logo-brand {
            position: absolute;
            top: -12px;
            left: -35px;
            @include bpSp {
              top: -10px;
              left: -20px;
            }
          }
        }
        &-content {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-top: 19px;
          @include bpSp {
            flex-direction: column;
            align-items: flex-end;
          }
          .txt {
            max-width: 242px;
            width: 56.3%;
            position: relative;
            text-align: left;
            @include bpSp {
              max-width: 100%;
              width: 100%;
              margin-bottom: 20px;
              @include f-size(14);
            }
          }
        }
      }
    }
  }
  .obj-img03 {
    margin-top: -115px;
    @include bpPcs {
      margin-top: -90px;
    }
    @include bpTb {
      margin-top: -50px;
    }
  }
  .business-wrap-box {
    display: flex;
    justify-content: space-between;
    @include bpTb {
      flex-direction: column;
    }
  }
  &__business {
    position: relative;
    padding: 0 54px 55px;
    @include bpSp {
      padding-top: 40px;
      padding-bottom: 20px;
      padding-left: 0px;
      padding-right: 0px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 26.2%;
      width: 100%;
      height: 480px;
      background: #d6f0ff;
      background: linear-gradient(90deg, #d6f0ff 0%, #eeffd9 100%);
      z-index: -1;
      @include bpPcs {
        width: 110%;
        left: -5%;
      }
      @include bpSp {
        top: 34.2%;
        height: 51.5%;
      }
    }
    .m-title-img {
      @include bpSp {
        text-align: center;
        img {
          width: 200px;
        }
      }
    }
    .main-img {
      display: block;
      text-align: center;
      @include bpTb {
        width: 80%;
        margin: 0 auto;
      }
      @include bpSp {
        width: 100%;
        margin-top: 30px;
      }
    }
    .business-wrap {
      width: 48.7%;
      margin-top: 23px;
      @include bpPcs {
        padding: 0;
      }
      @include bpTb {
        width: 100%;
      }
      .ttl {
        position: relative;
        @include f-size(20);
        font-weight: 500;
        color: $color6;
        padding-left: 43px;
        @include bpSp {
          @include f-size(16);
          padding-left: 25px;
        }
        &:before {
          content: '';
          position: absolute;
          top: 17px;
          left: 0;
          width: 25px;
          background-color: $color6;
          height: 1px;
          @include bpSp {
            width: 15px;
            top: 15px;
          }
        }
      }
      &__list {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          width: 47.5%;
          @include bpSp {
            width: 48%;
            margin-bottom: 15px;
          }
          .title {
            @include f-size(16);
            font-weight: 500;
            padding-top: 12px;
            padding-bottom: 12px;
            @include bpSp {
              @include f-size(14);
              height: 65px;
              padding-top: 12px;
            }
          }
          .btn-wrap {
            text-align: right;
          }

          .m-linkblock {
            &:hover {
              .btn-wrap {
                .m-btn03 {
                  &:after {
                    right: -4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .obj-img04 {}
  &__links {
    margin-top: 45px;
    padding: 0 60px;
    position: relative;
    @include bpPcs {
      padding: 0;
    }
    &:before {
      content: '';
      position: absolute;
      max-width: 755px;
      width: 80%;
      height: 491.5px;
      background: #d6f0ff;
      background: linear-gradient(90deg, #d6f0ff 0%, #eeffd9 100%);
      z-index: -1;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
      @include bpSp {
        display: none;
      }
    }
    .links-wrap01 {
      display: flex;
      justify-content: space-between;
      padding-top: 77.5px;
      flex-wrap: wrap;
      @include bpSp {
        padding-top: 0;
      }
      .item {
        width: 30.5%;
        @include bpSp {
          width: 80%;
          margin: 0 auto 30px;
          max-width: 305px;
        }
        .title {
          @include f-size(16);
          font-weight: 500;
          padding-top: 12px;
          padding-bottom: 12px;
          @include bpSp {
            @include f-size(14);
          }
        }
        .btn-wrap {
          text-align: right;
        }
        .m-linkblock {
          &:hover {
            .btn-wrap {
              .m-btn03 {
                &:after {
                  right: -4px;
                }
              }
            }
          }
        }
      }
    }
    .links-wrap02 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 50px;
      margin-top: 55px;
      @include bpPcs {
        padding: 0;
      }
      @include bpTb {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @include bpSp {
        margin-top: 0;
      }
      .item {
        display: flex;
        border: 1px solid #646464;
        max-width: 433px;
        width: 48.112%;
        justify-content: space-between;
        padding: 58px 29px;
        background-color: $color1;
        align-items: center;
        @include bpTb {
          padding: 40px 29px;
          margin: 10px 0;
        }
        @include bpSp {
          padding: 26px 15px;
          width: 90%;
        }
        &:hover {
          text-decoration: none;
          border-color: $color2;
          opacity: 0.85;
          .m-btn03::after {
            right: -7px;
          }
        }
        .txt {
          @include f-size(18);
          font-weight: 500;
          @include bpSp {
            @include f-size(16);
          }
        }
      }
    }
    .recruit-wrap {
      display: block;
      margin-top: 70px;
      padding-top: 84px;
      padding-bottom: 75px;
      text-align: center;
      position: relative;
      overflow: hidden;
      // &::before {
      //   content: '';
      //   display: block;
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   background-image: url(../../img/top/bg-recruit.jpg);
      //   background-size: cover;
      //   background-repeat: no-repeat;
      //   z-index: -1;
      //   transition: all .5s;
      // }
      @include bpPcs {
        margin: 70px 50px 0;
      }
      @include bpSp {
        margin: 70px 0 0;
        width: 110%;
        margin-left: -5%;
        padding-top: 16%;
        padding-bottom: 16%;
      }
      &-bg {
        position: absolute;
        top: 0px;
        left: 0px;
      }
      &:hover {
        text-decoration: none;
        &::before {
          transform: scale(1.05);
        }
      }
      .m-title-img {
        position: relative;
        z-index: 1;
        &__ja {
          color: $color1;
          @include bpSp {
            margin-top: 0;
          }
        }
        @include bpSp {
          img {
            width: 190px;
          }
        }
      }
      .txt {
        position: relative;
        z-index: 1;
        margin-top: 11px;
        @include f-size(24);
        font-weight: 500;
        color: $color1;
        @include bpSp {
          font-size: 3vw;
        }
      }
    }
  }
  .obj-img05 {
    margin-top: -8px;
    margin-bottom: 84px;
    @include bpSp {
      margin-top: 30px;
    }
  }
}
